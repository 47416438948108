import React from "react";
import Slider from "react-slick";
import { Container, Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Container
      sx={{
        // background: "red",
        width: {
          md: "75%",
          xs: "100%",
        },
      }}
    >
      <Slider {...settings}>
        {[1, 2, 3, 4].map((image, index) => (
          <Box key={index} component="div">
            <img
              src={require(`../images/hero${image}.jpeg`)}
              alt={`hero${image}`}
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default HeroCarousel;
