import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { db, getMealById, storage } from "../utils";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { Meal } from "../interfaces";

const AddMealPage: React.FC = () => {
  const nav = useNavigate();
  const { id } = useParams();

  const [meal, setMeal] = useState({
    name: "",
    description: "",
    image: null as File | null,
    pricePerPlate: "",
    pricePerLb: "",
    pricePerTray: "",
  });
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [busy, setBusy] = useState(false);
  const [busy2, setBusy2] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "image") {
      const file = e.target.files ? e.target.files[0] : null;
      setMeal({ ...meal, image: file });
      if (file) {
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        setPreviewUrl(null);
      }
    } else {
      setMeal({ ...meal, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Upload image to Firebase Storage
      setBusy(true);
      let downloadURL = "";
      if (id && previewUrl) downloadURL = previewUrl;
      if (meal.image) {
        const storageRef = ref(storage, `meals/${meal.image.name}`);
        await uploadBytes(storageRef, meal.image);
        downloadURL = await getDownloadURL(storageRef);
        setImageUrl(downloadURL);
      }
      const data = {
        name: meal.name,
        description: meal.description,
        imageUrl: downloadURL,
        pricePerLb: Number(meal.pricePerLb ?? "0"),
        pricePerPlate: Number(meal.pricePerPlate ?? "0"),
        pricePerTray: Number(meal.pricePerTray ?? "0"),
      };
      // Save meal details to Firestore
      if (!id)
        await addDoc(collection(db, "meals"), {
          ...data,
          ...{ createdOn: new Date() },
        });
      else await updateDoc(doc(db, "meals", id), data);

      setMeal({
        name: "",
        description: "",
        image: null,
        pricePerLb: "",
        pricePerPlate: "",
        pricePerTray: "",
      });
      setPreviewUrl(null);
      setError(null);
      setBusy(false);
      nav("/admin");
    } catch (err) {
      setBusy(false);
      setError("Error saving meal");
    }
  };

  const deleteMeal = async () => {
    setBusy2(true);
    if (id) await deleteDoc(doc(db, "meals", id));
    setBusy2(false);
    nav("/admin", { replace: true });
  };
  useEffect(() => {
    if (id) {
      getMealById(id).then((x) => {
        if (x) {
          const {
            name,
            imageUrl,
            description,
            pricePerLb,
            pricePerPlate,
            pricePerTray,
          } = x;
          setMeal({
            name,
            description,
            pricePerLb: pricePerLb.toString(),
            pricePerPlate: pricePerPlate.toString(),
            pricePerTray: pricePerTray.toString(),
            image: null,
          });
          if (imageUrl) setPreviewUrl(imageUrl);
        }
      });
    }
  }, [id]);
  return (
    <Container
      sx={{
        p: 2,
        width: { md: "50%", xs: "100%" },
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" gutterBottom>
        {id ? "Edit" : "Add"} Meal
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Meal Name"
          fullWidth
          margin="normal"
          value={meal.name}
          onChange={handleChange}
        />
        <TextField
          name="description"
          label="Description"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={meal.description}
          onChange={handleChange}
        />
        <TextField
          name="pricePerPlate"
          label="Price per Plate"
          fullWidth
          margin="normal"
          value={meal.pricePerPlate}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
        />
        <TextField
          name="pricePerTray"
          label="Price per Tray"
          fullWidth
          margin="normal"
          value={meal.pricePerTray}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
        />
        <TextField
          name="pricePerLb"
          label="Price per Pound"
          fullWidth
          margin="normal"
          value={meal.pricePerLb}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
        />
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="meal-image"
          type="file"
          name="image"
          onChange={handleChange}
        />
        <label htmlFor="meal-image">
          <Button
            variant="outlined"
            color="primary"
            component="span"
            sx={{ mr: 2 }}
            size="large"
          >
            {previewUrl ? "Change" : "Add"} Meal Photo
          </Button>
        </label>
        {previewUrl && (
          <div style={{ marginTop: "10px" }}>
            {/* <Typography variant="h6">Image Preview:</Typography> */}
            <img
              src={previewUrl}
              alt="Preview"
              style={{ width: "400px", maxHeight: "400px", objectFit: "cover" }}
            />
          </div>
        )}
        {error && <Typography color="error">{error}</Typography>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            busy ||
            (Number(meal.pricePerLb) === 0 &&
              Number(meal.pricePerPlate) === 0 &&
              Number(meal.pricePerTray) === 0)
          }
          size="large"
        >
          Save {busy && <CircularProgress color="inherit" sx={{ ml: 1 }} />}
        </Button>
        {id && (
          <Button
            variant="contained"
            color="error"
            disabled={busy2}
            size="large"
            sx={{ ml: 2 }}
            onClick={deleteMeal}
          >
            Delete
            {busy2 && <CircularProgress color="inherit" sx={{ ml: 1 }} />}
          </Button>
        )}
      </form>
    </Container>
  );
};

export default AddMealPage;
