import React, { useState, ChangeEvent, FormEvent } from "react";
import { Container, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import { Meal } from "../interfaces";
import { useNavigate } from "react-router-dom";
import MenuPage from "./MenuPage";

const AdminPortal: React.FC = () => {
  const nav = useNavigate();

  return (
    <Container sx={{ p: 2, minHeight: "50vh" }}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={() => nav("/admin/meal")}
      >
        Add Meal
      </Button>
      <MenuPage isAdmin />
    </Container>
  );
};

export default AdminPortal;
