// src/pages/CheckoutPage.tsx
import React, { useState } from "react";
import {
  Container,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useCart } from "../components/CartContext";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, formatOrderDetailsToHTML } from "../utils";
import emailjs from "@emailjs/browser";

const CheckoutPage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("pickup");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState({
    line1: "",
    city: "",
    state: "",
    zip: "",
  });
  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);

  const { cart, clearCart } = useCart();
  const nav = useNavigate();

  const totalCost = cart.reduce((total, item) => {
    const price = item[item.type] || 0;
    return total + price * item.quantity;
  }, 0);

  const handleSubmit = async () => {
    const orderDetails = {
      name,
      email,
      phone,
      deliveryMethod,
      date,
      time,
      address: deliveryMethod === "delivery" ? address : null,
      totalCost,
      cart,
    };

    try {
      setBusy(true);
      await addDoc(collection(db, "orders"), orderDetails);
      setOpen(true);
      emailjs.send(
        "service_dvvuoit",
        "template_sxp86iu",
        {
          message: formatOrderDetailsToHTML(orderDetails),
        },
        {
          publicKey: "zfJ9lSIiYtaJw11iS",
        }
      );
    } catch (error) {
      alert("Failed to submit order. Please try again.");
    } finally {
      setBusy(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    clearCart();
    nav("/");
  };

  return (
    <Container sx={{ p: 2 }}>
      <Button onClick={() => window.history.back()}>{"< "}Back</Button>
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
        Checkout
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
        Total: ${totalCost.toFixed(2)}
      </Typography>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Phone Number"
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <FormControl>
          <FormLabel>Delivery Method</FormLabel>
          <RadioGroup
            row
            value={deliveryMethod}
            onChange={(e) => setDeliveryMethod(e.target.value)}
          >
            <FormControlLabel
              value="pickup"
              control={<Radio />}
              label="Pickup"
            />
            <FormControlLabel
              value="delivery"
              control={<Radio />}
              label="Delivery"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label={`${deliveryMethod === "pickup" ? "Pickup" : "Delivery"} Date`}
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          label={`${deliveryMethod === "pickup" ? "Pickup" : "Delivery"} Time`}
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          InputLabelProps={{ shrink: true }}
          required
        />
        {deliveryMethod === "delivery" && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Address Line 1"
              value={address.line1}
              onChange={(e) =>
                setAddress({ ...address, line1: e.target.value })
              }
              required
            />
            <TextField
              label="City"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
              required
            />
            <TextField
              label="State"
              value={address.state}
              onChange={(e) =>
                setAddress({ ...address, state: e.target.value })
              }
              required
            />
            <TextField
              label="ZIP Code"
              value={address.zip}
              onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              required
            />
          </Box>
        )}
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          disabled={busy || !email || !name || !phone || !date || !time}
        >
          Submit Order {busy ? "..." : ""}
        </Button>
        <Typography sx={{ textAlign: "center" }} variant="caption">
          Make payment via CashApp or Zelle
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Order Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your order has been submitted successfully! We will contact you
            shortly. Make payment via CashApp or Zelle
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CheckoutPage;
