import React, { useState, ChangeEvent, FormEvent } from "react";
import { Container, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import { Order } from "../interfaces";

const OrderPage: React.FC = () => {
  const [order, setOrder] = useState<Order>({
    name: "",
    email: "",
    meal: "",
    quantity: 1,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    axios
      .post("/api/orders", order)
      .then((response) => {
        alert("Order placed successfully!");
      })
      .catch((error) => {
        alert("Error placing order");
      });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Place an Order
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Name"
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <TextField
          name="meal"
          label="Meal"
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <TextField
          name="quantity"
          label="Quantity"
          type="number"
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Order
        </Button>
      </form>
    </Container>
  );
};

export default OrderPage;
