import React from "react";
import { Button, Container, Typography } from "@mui/material";
import HeroCarousel from "../components/HeroCarousel";

const HomePage = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          mt: 1,
          textAlign: "center",
          fontSize: {
            md: "3rem",
            xs: "2rem",
          },
        }}
      >
        Welcome to Kess Catering Services
      </Typography>
      <HeroCarousel />

      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          mb: 1,
          mt: 3,
          fontSize: {
            md: "2rem",
            xs: "1.3rem",
          },
        }}
      >
        Celebrating Nigerian Cuisine with Every Event
      </Typography>
      <Typography
        variant="h5"
        sx={{
          textAlign: "left",
          mt: 3,
          mb: 2,
          fontSize: {
            md: "1.2rem",
            xs: "1rem",
            lineHeight: "1.5rem",
          },
        }}
      >
        At Kess Catering Services, we are dedicated to bringing the vibrant and
        diverse flavors of Nigerian cuisine to your special occasions. Whether
        it's a wedding, birthday, corporate event, or any celebration, our
        expertly crafted dishes are sure to delight your guests. Servicing the
        Florida area (Jacksonville, Orlando, Tampa, Miami)
      </Typography>
      <Button
        size="large"
        variant="contained"
        sx={{
          width: {
            xs: "100%",
            md: "50%",
          },
        }}
        href="/menu"
      >
        Place Order
      </Button>
    </Container>
  );
};

export default HomePage;
