// src/pages/CartPage.tsx
import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCart } from "../components/CartContext";
import { USDollar } from "../utils";

const CartPage: React.FC = () => {
  const { cart, updateCartQuantity, removeFromCart } = useCart();
  const nav = useNavigate();

  const totalCost = cart.reduce((total, item) => {
    const price = item[item.type] || 0;
    return total + price * item.quantity;
  }, 0);

  return (
    <Container sx={{ p: 2 }}>
      <Button onClick={() => nav(-1)}>{"< "}Back</Button>
      <Typography variant="h4" sx={{ mt: 2, mb: 2, textAlign: "center" }}>
        Cart
      </Typography>
      {cart.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pb: 1,
            mb: 2,
            borderBottom: "1px solid #AAA",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">
            Total: {USDollar.format(totalCost)}
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => nav("/checkout")}
          >
            Checkout
          </Button>
        </Box>
      )}
      <Grid container spacing={4}>
        {cart.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                Cart Empty
              </Typography>
              <Button variant="outlined" href="/menu">
                View Menu
              </Button>
            </Box>
          </Grid>
        )}
        {cart.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {item.name}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.description}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",

                    justifyContent: "space-between",
                    // background: "red",
                  }}
                >
                  {/* <img
                    src={
                      item.imageUrl
                        ? item.imageUrl
                        : "https://cdn-icons-png.freepik.com/512/8685/8685621.png"
                    }
                    alt="image-prev"
                    style={{
                      height: "50px",
                      // maxHeight: "300px",
                      objectFit: "cover",
                    }}
                  /> */}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                      type="number"
                      label="Quantity"
                      value={item.quantity}
                      onChange={(e) =>
                        updateCartQuantity(
                          item.id,
                          item.type,
                          Number(e.target.value)
                        )
                      }
                      InputProps={{ inputProps: { min: 1 } }}
                      sx={{ width: 80, ml: 0, mt: 1 }}
                    />
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "green" }}
                    >
                      ${item[item.type]} per{" "}
                      {item.type === "pricePerPlate"
                        ? "Plate"
                        : item.type === "pricePerTray"
                        ? "Tray"
                        : "Pound"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      gutterBottom
                      variant="body1"
                      sx={{ flex: 1, textAlign: "right" }}
                    >
                      Total: {USDollar.format(item[item.type] * item.quantity)}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => removeFromCart(item.id, item.type)}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button href="/menu" sx={{ mt: 2 }}>
        + Add more Items
      </Button>
    </Container>
  );
};

export default CartPage;
