import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  IconButton,
} from "@mui/material";
import { getMenu } from "../utils";
import { useNavigate } from "react-router-dom";
import { Meal } from "../interfaces";
import { useCart } from "../components/CartContext";
import OrderModal from "./OrderModal";
import { Close } from "@mui/icons-material";

const MenuPage: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const nav = useNavigate();
  const [meals, setMeals] = useState<Meal[]>([]);
  const [busy, setBusy] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState<Meal | null>(null);
  const [orderedMeal, setOrderedMeal] = useState<Meal | null>(null);

  const [quantities, setQuantities] = useState<{
    [key: string]: { [key: string]: number };
  }>({});
  const { addToCart } = useCart();

  useEffect(() => {
    setBusy(true);
    getMenu()
      .then(setMeals)
      .finally(() => {
        setBusy(false);
      });
  }, []);

  const handleQuantityChange = (
    mealId: string,
    type: "pricePerPlate" | "pricePerTray" | "pricePerLb",
    value: number
  ) => {
    setQuantities((prev) => ({
      ...prev,
      [mealId]: {
        ...prev[mealId],
        [type]: value,
      },
    }));
  };

  const handleAddToCart = (
    meal: Meal,
    type: "pricePerPlate" | "pricePerTray" | "pricePerLb"
  ) => {
    const quantity = quantities[meal.id]?.[type] || 1;
    const price = meal[type];
    addToCart({ ...meal, type, quantity });
  };

  const order = (meal: Meal) => {
    setSelectedMeal(meal);
  };
  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ mt: 2, mb: 2, textAlign: "center" }}>
        Our Menu
      </Typography>
      {busy && <CircularProgress />}
      {isAdmin && meals.length === 0 && (
        <Typography>
          No menu items present. Tap the Add meal to add meals
        </Typography>
      )}
      <Grid container spacing={4}>
        {meals.map((meal) => (
          <Grid item key={meal.id} xs={12} sm={6} md={6}>
            <Card
              sx={{ width: "100%", cursor: "pointer" }}
              //   onClick={() => order(meal)}
            >
              <img
                src={
                  meal.imageUrl
                    ? meal.imageUrl
                    : "https://cdn-icons-png.freepik.com/512/8685/8685621.png"
                }
                alt="image-prev"
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "cover",
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {meal.name}
                </Typography>
                <Typography gutterBottom variant="body2">
                  {meal.description}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  sx={{ color: "green" }}
                >
                  Starting at $
                  {Math.min(
                    ...[
                      meal.pricePerPlate,
                      meal.pricePerLb,
                      meal.pricePerTray,
                    ].filter((x) => x > 0)
                  )}
                </Typography>
                {!isAdmin && (
                  <Button variant="outlined" onClick={() => order(meal)}>
                    ORDER
                  </Button>
                )}
                {/* {meal.pricePerPlate > 0 && (
                  <div>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "green" }}
                    >
                      ${meal.pricePerPlate} per Plate
                    </Typography>
                    <TextField
                      type="number"
                      label="Quantity"
                      value={quantities[meal.id]?.pricePerPlate || 1}
                      onChange={(e) =>
                        handleQuantityChange(
                          meal.id,
                          "pricePerPlate",
                          +e.target.value
                        )
                      }
                      inputProps={{ min: 1 }}
                      sx={{ mr: 1 }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddToCart(meal, "pricePerPlate")}
                    >
                      Add to Cart
                    </Button>
                  </div>
                )}
                {meal.pricePerTray > 0 && (
                  <div>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "green" }}
                    >
                      ${meal.pricePerTray} per Tray
                    </Typography>
                    <TextField
                      type="number"
                      label="Quantity"
                      value={quantities[meal.id]?.pricePerTray || 1}
                      onChange={(e) =>
                        handleQuantityChange(
                          meal.id,
                          "pricePerTray",
                          +e.target.value
                        )
                      }
                      inputProps={{ min: 1 }}
                      sx={{ mr: 1 }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddToCart(meal, "pricePerTray")}
                    >
                      Add to Cart
                    </Button>
                  </div>
                )}
                {meal.pricePerLb > 0 && (
                  <div>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: "green" }}
                    >
                      ${meal.pricePerLb} per pound
                    </Typography>
                    <TextField
                      type="number"
                      label="Quantity"
                      value={quantities[meal.id]?.pricePerLb || 1}
                      onChange={(e) =>
                        handleQuantityChange(
                          meal.id,
                          "pricePerLb",
                          +e.target.value
                        )
                      }
                      inputProps={{ min: 1 }}
                      sx={{ mr: 1 }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddToCart(meal, "pricePerLb")}
                    >
                      Add to Cart
                    </Button>
                  </div>
                )} */}
                {isAdmin && (
                  <Button
                    variant="outlined"
                    onClick={() => nav(`/admin/meal/${meal.id}`)}
                  >
                    Edit
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedMeal !== null && (
        <OrderModal
          meal={selectedMeal}
          open={selectedMeal !== null}
          onClose={(m) => {
            setSelectedMeal(null);
            if (m) setOrderedMeal(m);
          }}
        />
      )}
      <Snackbar
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#ffffff", // Set your desired background color here
            color: "#000000", // Set your desired text color here
            // width: "90vw",
          },
        }}
        open={orderedMeal !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setOrderedMeal(null)}
        message="Item added to cart"
        action={
          <React.Fragment>
            <Button
              color="primary"
              size="small"
              onClick={() => nav("/cart")}
              variant="contained"
            >
              View CART
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOrderedMeal(null)}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
};

export default MenuPage;
