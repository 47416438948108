import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { Meal } from "../interfaces";
import { useCart } from "../components/CartContext";
import { USDollar } from "../utils";

interface OrderModalProps {
  meal: Meal | null;
  open: boolean;
  onClose: (meal?: Meal | null) => void;
}

const OrderModal: React.FC<OrderModalProps> = ({ meal, open, onClose }) => {
  const [quantity, setQuantity] = useState({
    pricePerPlate: 1,
    pricePerTray: 1,
    pricePerLb: 1,
  });
  const { addToCart } = useCart();

  if (!meal) return null;

  const handleAddToCart = (
    type: "pricePerPlate" | "pricePerTray" | "pricePerLb"
  ) => {
    const price = meal[type];
    addToCart({ ...meal, type, quantity: quantity[type] });
    onClose(meal);
  };

  const props: ("pricePerPlate" | "pricePerTray" | "pricePerLb")[] = [
    "pricePerPlate",
    "pricePerTray",
    "pricePerLb",
  ];

  return (
    <Dialog open={open} onClose={() => onClose(null)} sx={{ m: -3 }}>
      <DialogTitle>Order {meal.name}</DialogTitle>
      <DialogContent sx={{ border: "1px solid #eee", p: 2 }}>
        <img
          src={
            meal.imageUrl
              ? meal.imageUrl
              : "https://cdn-icons-png.freepik.com/512/8685/8685621.png"
          }
          alt="image-prev"
          style={{
            width: "100%",
            maxHeight: "300px",
            objectFit: "cover",
          }}
        />
        <Typography variant="body1" sx={{ mb: 2, mt: 1 }}>
          {meal.description}
        </Typography>
        {props
          .filter((f) => meal[f] > 0)
          .map((prop) => (
            <Box
              key={prop}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ flex: 1, mr: 1, color: "green", fontWeight: "bold" }}
              >
                ${meal[prop]} per {prop.replace("pricePer", "")}
              </Typography>
              <TextField
                label="Quantity"
                type="number"
                value={quantity[prop]}
                onChange={(e) =>
                  setQuantity((p) => ({
                    ...p,
                    ...{ [prop]: Number(e.target.value) },
                  }))
                }
                inputProps={{ min: 1 }}
                margin="normal"
                sx={{ width: 80 }}
                helperText={USDollar.format(quantity[prop] * meal[prop])}
              />
              <Button
                variant="contained"
                onClick={() => handleAddToCart(prop)}
                size="large"
                sx={{ ml: 1 }}
              >
                Add to Order
              </Button>
            </Box>
          ))}
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 4 }}>
        <Button
          variant="contained"
          onClick={() => onClose(null)}
          color="error"
          size="large"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderModal;
