// src/contexts/CartContext.tsx
import React, { createContext, useContext, useState } from "react";
import { Meal } from "../interfaces";

interface CartItem extends Meal {
  quantity: number;
  type: "pricePerPlate" | "pricePerTray" | "pricePerLb";
}

interface CartContextProps {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  updateCartQuantity: (
    id: string,
    type: CartItem["type"],
    quantity: number
  ) => void;
  removeFromCart: (id: string, type: CartItem["type"]) => void;
  clearCart: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cart, setCart] = useState<CartItem[]>([]);

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (cartItem) => cartItem.id === item.id && cartItem.type === item.type
      );
      if (existingItemIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += item.quantity;
        return updatedCart;
      }
      return [...prevCart, item];
    });
  };

  const updateCartQuantity = (
    id: string,
    type: CartItem["type"],
    quantity: number
  ) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item) => {
        if (item.id === id && item.type === type) {
          return { ...item, quantity };
        }
        return item;
      });
      return updatedCart;
    });
  };

  const removeFromCart = (id: string, type: CartItem["type"]) => {
    setCart((prevCart) =>
      prevCart.filter((item) => !(item.id === id && item.type === type))
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, updateCartQuantity, removeFromCart, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
